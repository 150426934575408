<template>
  <v-col class="px-5 pt-2 pb-5 height-100 d-flex flex-column">
    <v-data-table
      :headers="headerProjectTable"
      :items="allProjects"
      :items-per-page="-1"
      hide-default-footer
      class="mt-5 orders-table table-vertical-line"
      @click:row="showProject"
    >
      <template v-slot:header.name>
        <v-text-field
          v-model="searchProject"
          @input="debouncedInput('searchCompany')"
          @click.stop
          prepend-inner-icon="$search"
          placeholder="Company"
          outlined
          clearable
          dense
          class="table-search width-140 d-inline-block ma-0 input-clear-small"
        ></v-text-field>
      </template>
      <template v-slot:item.orderCount="data">
        <v-chip
          label
          small
          color="#EEF2F7"
          class="px-1 justify-center min-width-24"
        >
          <span class="one-line fz-13 text-dgray font-weight-regular">{{ data.item.ordersCount }} </span>
        </v-chip>
      </template>
      <template v-slot:item.orderSum="data">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              style="white-space: nowrap"
            >
              {{ Math.round(data.item.ordersSumRub) | numberFormat }}₽
            </div>
          </template>
          <template>
            <div style="white-space: nowrap">
              ${{ (+data.item.ordersSumUsd).toFixed(2) | numberFormat }}
            </div>
          </template>
        </v-tooltip>
      </template>
      <template v-slot:item.delete="data">
        <RemoveDialog
          @commit="deleteProject(data.item.id, () => { getProjects(); showProjectFrame = false })"
        >
          <template v-slot:default>
            <v-btn
              elevation="0"
              x-small
              icon
              fab
            >
              <v-icon size="20"  class="stroke-gray">$delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:message>
            <b>"{{ data.item.name }}"</b>
          </template>
        </RemoveDialog>
      </template>
    </v-data-table>
    <v-row no-gutters>
      <v-dialog
        v-model="showCreatingProjectFrame"
        @input="inputCreatingProjectFrame"
        content-class="rounded-xl"
        overlay-color="rgba(9, 24, 73, 0.2)"
        overlay-opacity="1"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            height="50"
            text
            tile
            color="#7552CC"
            class="text-none px-3 fz-14"
          >
            <b class="fz-16 fw-600 mr-1"> + </b>
            Add new order
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card class="pa-6">
            <v-card-title class="align-start">
              <div>
                <div class="mb-2 d-flex align-center">
                  <span>Create Project</span>
                </div>
                <v-form v-model="validation" ref="creatingProjectInput">
                  <v-text-field
                    v-model="newProjectName"
                    :rules="[$rules.required]"
                    outlined
                    single-line
                    dense
                    class="rounded-lg fz-14 input-border font-weight-medium text-black"
                  />
                </v-form>
              </div>
              <v-spacer/>
              <v-icon class="close-icon" @click="dialog.value = false">$close</v-icon>
            </v-card-title>
            <v-card-actions class="justify-end pa-0">
              <v-btn
                @click="dialog.value = false"
                elevation="0"
                color="primary"
                height="40"
                width="155"
                outlined
                class="ml-7 mr-3 rounded-lg fz-16 fw-600 text-none"
              >
                Cancel
              </v-btn>
              <v-btn
                :disabled="!validation"
                @click="saveProject"
                elevation="0"
                color="primary"
                height="40"
                width="155"
                class="rounded-lg fz-16 fw-600 text-none"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
    <v-row no-gutters class="flex-grow-0">
      <v-btn
        :disabled="pageOptions.page === 1"
        @click="pageOptions.page--"
        color="primary"
        height="40"
        outlined
        class="my-auto pl-2 mr-1 rounded-lg text-none fz-14 fw-600"
      >
        <v-icon>mdi-chevron-left</v-icon>
        Previous
      </v-btn>
      <v-pagination
        v-model="pageOptions.page"
        :length="pageOptions.totalPages"
        :total-visible="7"
        previous-aria-label="Previous"
        class="my-4 pagination text-black fw-600"
      />
      <v-btn
        @click="pageOptions.page++"
        :disabled="pageOptions.totalPages === pageOptions.page"
        color="primary"
        height="40"
        outlined
        class="my-auto ml-1 pr-2 rounded-lg text-none fz-14 fw-600"
      >
        Next
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    <v-dialog
      v-model="showProjectFrame"
      content-class="rounded-xl"
      overlay-color="rgba(9, 24, 73, 0.2)"
      overlay-opacity="1"
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card class="pa-6">
        <v-card-title class="align-start">
          <div>
            <div class="mb-2 d-flex align-center">
              <span>Project</span>
            </div>
            <v-text-field
                v-model="project.name"
                :disabled="!editMode"
                :readonly="!editMode"
                :background-color="editMode ? 'transparent' : '#F6F8F9'"
                outlined
                single-line
                dense
                class="rounded-lg fz-14 input-border font-weight-medium text-black"
              />
          </div>
          <v-spacer/>
          <v-icon class="close-icon" @click="showProjectFrame = false">$close</v-icon>
        </v-card-title>
        <v-card-text class="scroll" style="height: 400px;">
          <!--:items="ordersProject"-->
          <v-data-table
            :headers="headerOrdersTable"
            :items="project ? project.orders : []"
            :items-per-page="-1"
            @click:row="$refs.editOrder.getOrder($event.id)"
            hide-default-footer
            class="mt-5 orders-table table-vertical-line"
          >
            <template v-slot:item.status="{ item }">
              <OrderStatus
                :status="item.status"
                :cancellation-reasons="item.itemsCancellationReasons"
              />
            </template>
            <template v-slot:item.dateStart="{ item }">
              {{ item.dateStart ? formatDate(item.dateStart) : '' }}
            </template>
            <template v-slot:item.dateEnd="{ item }">
              <div>
                  <span class="one-line"
                        :class="{'color-primary': item.dateEndPrediction && item.dateEnd !== item.dateEndPrediction, 'fw-600': item.dateEndPrediction && item.dateEnd !== item.dateEndPrediction}">
                    {{ item.dateEnd ? formatDate(item.dateEnd) : '' }}
                  </span>
              </div>
              <div v-if="item.dateEndPrediction && item.dateEnd !== item.dateEndPrediction">
                  <span class="one-line mt-n1">
                    {{ formatDate(item.dateEndPrediction) }}
                  </span>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="justify-end pa-0">
          <v-btn
            @click="editMode ? editMode = false : showProjectFrame = false"
            elevation="0"
            color="primary"
            height="40"
            width="155"
            outlined
            class="ml-7 mr-3 rounded-lg fz-16 fw-600 text-none"
          >
            {{ editMode ? 'Cancel' : 'Exit' }}
          </v-btn>
          <v-btn
            @click="editMode ? updateProjectLocal() : editMode = true"
            elevation="0"
            color="primary"
            height="40"
            width="155"
            class="rounded-lg fz-16 fw-600 text-none"
          >
            {{ editMode ? 'Save' : 'Edit' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <EditOrder
      @getOrders="getSpecificProject(project.id)"
      ref="editOrder"
    />
  </v-col>
</template>

<script>
import EditOrder from '@/components/App/Orders/EditOrder'
import numberFormat from '@/helpers/numberFormat'
import OrderStatus from '@/components/App/UI/OrderStatus'
import RemoveDialog from '@/components/App/Functional/RemoveDialog'
import projects from '@/components/Hooks/Projects'
import { debounce } from 'lodash'
import { Locale } from 'v-calendar'

const locale = new Locale()

export default {
  name: 'ProjectTab',
  filters: {
    numberFormat
  },
  components: {
    EditOrder,
    OrderStatus,
    RemoveDialog
  },
  data() {
    return {
      editMode: false,
      validation: false,
      headerProjectTable: [
        {
          text: 'Project',
          value: 'name',
          width: '50%',
          sortable: false
        },
        {
          text: 'Orders',
          value: 'orderCount',
          width: '25%',
          sortable: false
        },
        {
          text: 'Sum',
          value: 'orderSum',
          width: '25%',
          sortable: false
        },
        {
          text: '',
          value: 'delete',
          align: 'end',
          sortable: false
        }
      ],
      headerOrdersTable: [
        {
          text: 'Number',
          value: 'id',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false
        },
        {
          text: 'Date start',
          value: 'dateStart',
          sortable: false
        },
        {
          text: 'Date End',
          value: 'dateEnd',
          sortable: false
        }
      ],
      newProjectName: null,
      searchProject: '',
      showProjectFrame: false,
      showCreatingProjectFrame: false,
      ordersProject: []
    }
  },
  methods: {
    debouncedInput: debounce(function () {
      this.getProjects(this.searchProject)
    }, 1000),
    inputCreatingProjectFrame() {
      this.newProjectName = null
    },
    saveProject() {
      if (this.$refs.creatingProjectInput.validate()) {
        this.createProject(this.newProjectName, () => {
          this.getProjects()
          this.showCreatingProjectFrame = false
          this.newProjectName = null
        })
      }
    },
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    showProject(item) {
      this.getSpecificProject(item.id)
      this.showProjectFrame = true
    },
    updateProjectLocal() {
      this.updateProject({ id: this.project.id, name: this.project.name }, () => {
        this.getProjects()
      })
      this.editMode = false
    }
  },
  created() {
    this.getProjects()
  },
  setup() {
    const {
      allProjects,
      project,
      pageOptions,
      getProjects,
      getSpecificProject,
      createProject,
      updateProject,
      deleteProject
    } = projects()
    return {
      getProjects,
      getSpecificProject,
      createProject,
      updateProject,
      deleteProject,
      allProjects,
      project,
      pageOptions
    }
  },
  watch: {
    'pageOptions.page': {
      handler() {
        this.getProjects(null, this.pageOptions.page)
      }
    }
  }
}
</script>

<style scoped>

</style>
